<template>
  <div>
    <v-form>
      <ValidationObserver ref="addressInfoValidationObserver">
        <v-row class="ma-4">
          <v-col cols="12" class="mb-8">
            <div class="body-1 primary--text font-weight-medium my-2">
              Permanent Address
              <span style="color: red">*</span>
            </div>
            <div class="d-flex py-3" style="width: 100%">
              <vue-google-autocomplete
                id="map"
                class="form-control pa-3"
                style="width: 100%; border: 1px solid #e0e0e0"
                placeholder="Please type your address"
                v-on:placechanged="setPermanentAddress"
                :fields="['address_components', 'geometry']"
                :enable-geolocation="true"
              ></vue-google-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="pApartmentNameProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(234).Field_Alias"
              rules="required|multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'pApartmentName'"
                v-model="pApartmentName"
                label="Street 1*"
                outlined
                :counter="255"
                :maxlength="255"
                :error-messages="errors"
                @input="onChangePermanentAddress()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(234).Field_Alias }}
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="pStreetProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(240).Field_Alias"
              rules="multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'pStreet'"
                v-model="pStreet"
                outlined
                :label="getFieldAlias(240).Field_Alias"
                :counter="255"
                :maxlength="255"
                :error-messages="errors"
                @input="onChangePermanentAddress()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(240).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(240).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="pCityProvider"
              v-slot="{ errors }"
              name="City"
              rules="required|multilingualNameNumericValidation"
            >
              <v-autocomplete
                v-if="getFieldAlias(137).Predefined == 'Yes'"
                :id="'pCity'"
                v-model="pCity"
                :items="cityStateList"
                item-text="cityStateDetails"
                item-value="cityStateDetails"
                outlined
                :error-messages="errors"
                @change="onChangePermanentAddress()"
              >
                <template v-slot:label>
                  City
                  <span style="color: red">*</span>
                </template>
              </v-autocomplete>
              <v-text-field
                v-else
                :id="'pCity'"
                v-model="pCity"
                outlined
                :error-messages="errors"
                @input="onChangePermanentAddress()"
              >
                <template v-slot:label>
                  City
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="pStateProvider"
              v-slot="{ errors }"
              name="State"
              rules="required|multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'pState'"
                v-model="pState"
                outlined
                :readonly="
                  pState && getFieldAlias(137).Predefined == 'Yes'
                    ? true
                    : false
                "
                :error-messages="errors"
                @input="onChangePermanentAddress()"
              >
                <template v-slot:label>
                  State/Province<span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="pCountryProvider"
              v-slot="{ errors }"
              name="Country"
              rules="required|multilingualNameNumericValidation"
            >
              <v-autocomplete
                :id="'pCountry'"
                v-model="pCountry"
                :items="countryList"
                item-text="Country_Name"
                item-value="Country_Code"
                outlined
                label="Country*"
                :error-messages="errors"
                @change="onChangePermanentAddress()"
              >
                <template v-slot:label>
                  Country
                  <span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="pPincodeProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(144).Field_Alias"
              rules="required|multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'pPincode'"
                v-model="pPincode"
                outlined
                :counter="30"
                :maxlength="30"
                label="Postal Code*"
                :error-messages="errors"
                @input="onChangePermanentAddress()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(144).Field_Alias }}
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <div class="body-1 primary--text font-weight-medium my-2">
              Current Address*
            </div>
            <div style="max-width: 260px">
              <v-switch
                v-model="sameAsPermanent"
                inset
                :true-value="1"
                :false-value="0"
                color="primary"
                label="Same as permanent address"
                @change="onChangeAddressFlag()"
              ></v-switch>
            </div>
            <div
              v-if="!sameAsPermanent"
              class="d-flex pb-3 mb-8"
              style="width: 100%"
            >
              <vue-google-autocomplete
                id="map1"
                class="form-control pa-3"
                style="width: 100%; border: 1px solid #e0e0e0"
                placeholder="Please type your address"
                v-on:placechanged="setCurrentAddress"
                :enable-geolocation="true"
                :fields="['address_components', 'geometry']"
              ></vue-google-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="cApartmentNameProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(235).Field_Alias"
              rules="required|multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'cApartmentName'"
                v-model="cApartmentName"
                label="Street 1*"
                outlined
                :counter="255"
                :maxlength="255"
                :error-messages="errors"
                :disabled="sameAsPermanent ? true : false"
              >
                <template v-slot:label>
                  {{ getFieldAlias(235).Field_Alias }}
                  <span style="color: red">*</span>
                </template></v-text-field
              >
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="cStreetProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(240).Field_Alias"
              rules="multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'cStreet'"
                v-model="cStreet"
                outlined
                :label="getFieldAlias(240).Field_Alias"
                :counter="255"
                :maxlength="255"
                :error-messages="errors"
                :disabled="sameAsPermanent ? true : false"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="cCityProvider"
              v-slot="{ errors }"
              name="Current City"
              rules="required|multilingualNameNumericValidation"
            >
              <v-autocomplete
                v-if="getFieldAlias(137).Predefined == 'Yes'"
                :id="'cCity'"
                v-model="cCity"
                :items="cityStateList"
                item-text="cityStateDetails"
                item-value="cityStateDetails"
                outlined
                :counter="50"
                :maxlength="50"
                label="City*"
                :error-messages="errors"
                :disabled="sameAsPermanent ? true : false"
              >
                <template v-slot:label>
                  City<span style="color: red">*</span>
                </template></v-autocomplete
              >
              <v-text-field
                v-else
                :id="'cCity'"
                v-model="cCity"
                outlined
                :error-messages="errors"
                @input="onChangePermanentAddress()"
                :disabled="sameAsPermanent ? true : false"
              >
                <template v-slot:label>
                  City
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="cStateProvider"
              v-slot="{ errors }"
              name="Current State"
              rules="required|multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'cState'"
                v-model="cState"
                outlined
                :readonly="
                  cState && getFieldAlias(137).Predefined == 'Yes'
                    ? true
                    : false
                "
                label="State*"
                :error-messages="errors"
                :disabled="sameAsPermanent ? true : false"
              >
                <template v-slot:label>
                  State/Province<span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="cCountryProvider"
              v-slot="{ errors }"
              name="Current Country"
              rules="required"
            >
              <v-autocomplete
                :id="'cCountry'"
                v-model="cCountry"
                :items="countryList"
                item-text="Country_Name"
                item-value="Country_Code"
                outlined
                :readonly="cCountry ? true : false"
                label="Country*"
                :error-messages="errors"
                @change="onChangePermanentAddress()"
                :disabled="sameAsPermanent ? true : false"
              >
                <template v-slot:label>
                  Country
                  <span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="cPincodeProvider"
              v-slot="{ errors }"
              :name="'Current ' + getFieldAlias(146).Field_Alias"
              rules="required|multilingualNameNumericValidation"
            >
              <v-text-field
                :id="'cPincode'"
                v-model="cPincode"
                outlined
                :counter="30"
                :maxlength="30"
                label="Postal Code*"
                :error-messages="errors"
                :disabled="sameAsPermanent ? true : false"
              >
                <template v-slot:label>
                  {{ getFieldAlias(146).Field_Alias }}
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <div class="body-1 primary--text font-weight-medium my-2">
              Contact Details
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <VueTelInput
              class="pa-2 pb-3"
              v-model="mobileNo"
              :preferred-countries="['IN', 'US', 'AU']"
              :error="!!mobileNumberValidation"
              error-color="#E53935"
              valid-color="#9E9E9E"
              :inputOptions="mobileNumberTranslation"
              :default-country="getCountryCode(mobileNoCountryCode)"
              :valid-characters-only="true"
              @input="updateMobileNumber"
            ></VueTelInput>
            <span
              :class="
                mobileNumberValidation
                  ? 'red--text caption mt-1'
                  : 'green--text caption mt-1'
              "
              >{{ mobileNumberValidation }}</span
            >
          </v-col>

          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="landlineNoProvider"
              v-slot="{ errors }"
              name="Telephone Number"
              rules="mobileNo"
            >
              <v-text-field
                :id="'landlineNo'"
                v-model="landlineNo"
                outlined
                :counter="30"
                :maxlength="30"
                label="Telephone Number"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="emergencyContactNameProvider"
              v-slot="{ errors }"
              name="Emergency Contact Name"
              rules="multilingualNameValidation"
            >
              <v-text-field
                :id="'emergencyContactName'"
                v-model="emergencyContactName"
                :counter="150"
                :max-length="150"
                outlined
                label="Emergency Contact Name"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="emergencyContactProvider"
              v-slot="{ errors }"
              name="Emergency Contact Number"
              :rules="emergencyNumberValidation"
            >
              <v-text-field
                :id="'emergencyContactNo'"
                v-model="emergencyContactNo"
                outlined
                :counter="30"
                :maxlength="30"
                label="Emergency Contact Number"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="emergencyContactRelationProvider"
              v-slot="{ errors }"
              name="Emergency Contact Relation"
              rules="alphaSpaceDot"
            >
              <v-text-field
                :id="'emergencyContactRelation'"
                v-model="emergencyContactRelation"
                outlined
                :counter="30"
                :maxlength="30"
                label="Emergency Contact Relation"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-form>
  </div>
</template>

<script>
import {
  LIST_COUNTRY,
  GET_CITY_LIST_WITH_STATE,
  GET_FORM_FIELDS_AND_TYPE,
} from "@/graphql/OnboardingQueries";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "AddressInfo",

  components: {
    VueGoogleAutocomplete,
    VueTelInput,
  },

  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    emergencyNumberValidation() {
      return "mobileNo|notSame:" + [this.mobileNo, "Mobile Number"];
    },
    mobileNumberValidation() {
      if (!this.mobileNo || !this.mobileNoCountryCode) {
        return "Mobile number is required / Provide a valid mobile number";
      } else if (this.mobileNo) {
        return this.mobileNumberProps.valid
          ? ""
          : "Please provide a valid mobile number";
      }
      return "";
    },
  },

  data: () => ({
    //   fields
    permanentAddress: "",
    currentAddress: "",
    pApartmentName: "",
    pStreet: "",
    pCity: "",
    pState: "",
    pCountry: "",
    pPincode: "",
    cApartmentName: "",
    cStreet: "",
    cCity: "",
    cState: "",
    cCountry: "",
    cPincode: "",
    emergencyContactNo: "",
    emergencyContactName: null,
    emergencyContactRelation: null,
    landlineNo: "",
    mobileNo: "",
    mobileNoCountryCode: "",
    cityStateList: [],

    //   others
    countryList: [],
    sameAsPermanent: 0,
    mobileNumberTranslation: {
      placeholder: "Mobile Number *",
    },
    mobileNumberProps: {},
    cityLoading: false,
    formList: [],
  }),

  watch: {
    pCity(val) {
      if (val != null) {
        for (let item of this.cityStateList) {
          if (item.cityStateDetails == val) {
            let details = item.cityStateDetails.split(", ");
            this.pState = details[1];
            this.pCountry = item.Country_Code;
          }
        }
      }
    },
    cCity(val) {
      if (val != null) {
        for (let item of this.cityStateList) {
          if (item.cityStateDetails == val) {
            let details = item.cityStateDetails.split(", ");
            this.cState = details[1];
            this.cCountry = item.Country_Code;
          }
        }
      }
    },
    sameAsPermanent(val) {
      if (val == false) {
        this.cApartmentName = "";
        this.cStreet = "";
        this.cCity = "";
        this.cState = "";
        this.cCountry = "";
        this.cPincode = "";
      }
    },
    personalInfo(val) {
      for (let item of this.cityStateList) {
        if (item.City_Name == val.pCity) {
          this.pCity = item.cityStateDetails;
        }
        if (item.City_Name == val.cCity) {
          this.cCity = item.cityStateDetails;
        }
      }
    },
  },

  apollo: {
    getCityListWithState: {
      query: GET_CITY_LIST_WITH_STATE,
      client: "apolloClientB",
      result({ data }) {
        this.cityStateList = data.getCityListWithState.cityDetails;
      },
    },
    listCountry: {
      query: LIST_COUNTRY,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listCountry) {
          this.countryList = data.listCountry.country;
        }
      },
    },
  },

  mounted() {
    scrollScreenToTop();
    this.getFieldProps();
    if (this.personalInfo && Object.keys(this.personalInfo).length > 0) {
      this.pApartmentName = this.personalInfo.pApartment_Name;
      this.pStreet = this.personalInfo.pStreet_Name;
      this.pState = this.personalInfo.pState;
      this.pCity = this.personalInfo.pCity;
      this.pCountry = this.personalInfo.pCountry;
      this.pPincode = this.personalInfo.pPincode;
      this.cApartmentName = this.personalInfo.cApartment_Name;
      this.cStreet = this.personalInfo.cStreet_Name;
      this.cCity = this.personalInfo.cCity;
      this.cState = this.personalInfo.cState;
      this.cCountry = this.personalInfo.cCountry;
      this.cPincode = this.personalInfo.cPincode;
      this.emergencyContactNo = this.personalInfo.Fax_No;
      this.mobileNo = this.personalInfo.Mobile_No
        ? this.personalInfo.Mobile_No
        : "";
      this.mobileNoCountryCode = this.personalInfo.Mobile_No_Country_Code
        ? this.personalInfo.Mobile_No_Country_Code
        : "";
      this.landlineNo = this.personalInfo.Land_Line_No;
      this.sameAsPermanent = this.personalInfo.Use_Location_Address;
      this.emergencyContactName = this.personalInfo.Emergency_Contact_Name;
      this.emergencyContactRelation =
        this.personalInfo.Emergency_Contact_Relation;

      for (let item of this.cityStateList) {
        if (this.pCity) {
          if (item.City_Name.toLowerCase() === this.pCity.toLowerCase()) {
            this.pCity = item.cityStateDetails;
          }
        }
        if (this.cCity) {
          if (item.City_Name.toLowerCase() === this.cCity.toLowerCase()) {
            this.cCity = item.cityStateDetails;
          }
        }
      }
    }
  },

  methods: {
    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },
    getFieldProps() {
      let vm = this;
      vm.saveLoading = true;
      this.$apollo
        .query({
          query: GET_FORM_FIELDS_AND_TYPE,
          variables: {
            form_Id: 178,
          },
          client: "apolloClientB",
        })
        .then((res) => {
          if (res.data.getFormFeildsByFormIdAndTab) {
            this.formList = res.data.getFormFeildsByFormIdAndTab.formFields;
          }
          vm.saveLoading = false;
        })
        .catch(() => {
          vm.saveLoading = false;
        });
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },

    getCountryCode(mobileNoCountryCode) {
      if (mobileNoCountryCode) {
        let countryCode = parseInt(mobileNoCountryCode);
        return countryCode;
      } else {
        return "";
      }
    },

    updateMobileNumber(payload, payload2) {
      this.mobileNumberProps = payload2;
      if (payload2 && Object.keys(payload2).length > 0) {
        this.mobileNo = payload2.nationalNumber;
        this.mobileNoCountryCode = "+" + payload2.countryCallingCode;
      }
    },

    resetPermanentAddress() {
      this.pApartmentName = "";
      this.pStreet = "";
      this.pCity = "";
      this.pState = "";
      this.pCountry = "";
      this.pPincode = "";
    },

    resetCurrentAddress() {
      this.cApartmentName = "";
      this.cStreet = "";
      this.cCity = "";
      this.cState = "";
      this.cCountry = "";
      this.cPincode = "";
    },

    //setting permanent address
    setPermanentAddress(addressData, fullData) {
      this.resetPermanentAddress();
      this.permanentAddress = fullData;
      for (const component of fullData.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "subpremise": {
            this.pApartmentName = component.long_name + "/";
            break;
          }

          case "street_number": {
            this.pApartmentName = this.pApartmentName + component.long_name;
            break;
          }

          case "street_address":
          case "premise":
          case "establishment":
          case "route": {
            this.pApartmentName && this.pApartmentName.length
              ? (this.pApartmentName =
                  this.pApartmentName + ", " + component.long_name)
              : (this.pApartmentName = component.long_name);
            break;
          }

          case "neighborhood":
          case "sublocality_level_3":
          case "sublocality_level_2":
          case "sublocality_level_1": {
            this.pStreet =
              this.pStreet && this.pStreet.length
                ? (this.pStreet = this.pStreet + ", " + component.long_name)
                : (this.pStreet = component.long_name);
            break;
          }

          case "locality": {
            this.pCity && this.pCity.length
              ? ""
              : (this.pCity = component.long_name);
            break;
          }

          case "administrative_area_level_2":
          case "administrative_area_level_3": {
            this.pCity && this.pCity.length
              ? this.pStreet && this.pStreet.length
                ? (this.pStreet = this.pStreet + ", " + component.long_name)
                : (this.pStreet = component.long_name)
              : (this.pCity = component.long_name);
            break;
          }

          case "administrative_area_level_1": {
            this.pCity && !this.pCity.length
              ? (this.pCity = component.long_name)
              : "";
            this.pState = component.long_name;
            break;
          }

          case "country": {
            this.pCountry = component.short_name;
            break;
          }

          case "postal_code": {
            this.pPincode = component.long_name;
            break;
          }

          case "postal_code_prefix":
          case "postal_code_suffix": {
            this.pPincode =
              this.pPincode && this.pPincode.length
                ? (this.pPincode = `${this.pPincode}-${component.long_name}`)
                : (this.pPincode = component.long_name);
            break;
          }
        }
      }
      let cityInDropdownFlag = false;
      for (let city of this.cityStateList) {
        if (city.City_Name.toLowerCase() == this.pCity.toLowerCase()) {
          this.pCity = city.cityStateDetails;
          cityInDropdownFlag = true;
        }
      }
      if (!cityInDropdownFlag) {
        this.pCity = "";
      }
      this.onChangeAddressFlag();
    },

    //setting current address
    setCurrentAddress(addressData, fullData) {
      this.resetCurrentAddress();
      this.currentAddress = fullData;
      for (const component of fullData.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "subpremise": {
            this.cApartmentName = component.long_name + "/";
            break;
          }

          case "street_number": {
            this.cApartmentName = this.cApartmentName + component.long_name;
            break;
          }

          case "street_address":
          case "premise":
          case "establishment":
          case "route": {
            this.cApartmentName && this.cApartmentName.length
              ? (this.cApartmentName =
                  this.cApartmentName + ", " + component.long_name)
              : (this.cApartmentName = component.long_name);
            break;
          }

          case "neighborhood":
          case "sublocality_level_3":
          case "sublocality_level_2":
          case "sublocality_level_1": {
            this.cStreet =
              this.cStreet && this.cStreet.length
                ? (this.cStreet = this.cStreet + ", " + component.long_name)
                : (this.cStreet = component.long_name);
            break;
          }

          case "locality": {
            this.cCity && this.cCity.length
              ? ""
              : (this.cCity = component.long_name);
            break;
          }

          case "administrative_area_level_2":
          case "administrative_area_level_3": {
            this.cCity && this.cCity.length
              ? this.cStreet && this.cStreet.length
                ? (this.cStreet = this.cStreet + ", " + component.long_name)
                : (this.cStreet = component.long_name)
              : (this.cCity = component.long_name);
            break;
          }

          case "administrative_area_level_1": {
            this.cCity && !this.cCity.length
              ? (this.cCity = component.long_name)
              : "";
            this.cState = component.long_name;
            break;
          }

          case "country": {
            this.cCountry = component.short_name;
            break;
          }

          case "postal_code": {
            this.cPincode = component.long_name;
            break;
          }

          case "postal_code_prefix":
          case "postal_code_suffix": {
            this.cPincode =
              this.cPincode && this.cPincode.length
                ? (this.cPincode = `${this.cPincode}-${component.long_name}`)
                : (this.cPincode = component.long_name);
            break;
          }
        }
      }
      let cityInDropdownFlag = false;
      for (let city of this.cityStateList) {
        if (city.City_Name.toLowerCase() == this.cCity.toLowerCase()) {
          this.cCity = city.cityStateDetails;
          cityInDropdownFlag = true;
        }
      }
      if (!cityInDropdownFlag) {
        this.cCity = "";
      }
      this.onChangeAddressFlag();
    },

    addressInfoDetails() {
      return {
        pApartment_Name: this.pApartmentName,
        pStreet_Name: this.pStreet,
        pCity: this.pCity ? this.pCity.split(", ")[0] : "",
        pState: this.pState,
        pCountry: this.pCountry,
        pPincode: this.pPincode,
        cApartment_Name: this.cApartmentName,
        cStreet_Name: this.cStreet,
        cCity: this.cCity ? this.cCity.split(", ")[0] : "",
        cState: this.cState,
        cCountry: this.cCountry,
        cPincode: this.cPincode,
        Use_Location_Address: this.sameAsPermanent,
        Land_Line_No: this.landlineNo,
        Mobile_No: this.mobileNo,
        Mobile_No_Country_Code: this.mobileNoCountryCode,
        Emergency_Contact_No: this.emergencyContactNo,
        Emergency_Contact_Name: this.emergencyContactName,
        Emergency_Contact_Relation: this.emergencyContactRelation,
      };
    },
    onChangeAddressFlag() {
      if (this.sameAsPermanent) {
        this.cApartmentName = this.pApartmentName;
        this.cStreet = this.pStreet;
        this.cCity = this.pCity;
        this.cState = this.pState;
        this.cCountry = this.pCountry;
        this.cPincode = this.pPincode;
      }
    },
    validateAddressInfo() {
      return new Promise((resolve) => {
        this.$refs.addressInfoValidationObserver
          .validate()
          .then((validationResponse) => {
            let invalidFields = [];
            Object.keys(this.$refs).forEach((refName) => {
              const field = this.$refs[refName];
              if (field && field.errors && field.errors.length > 0) {
                invalidFields.push(refName);
              }
            });
            if (invalidFields.length > 0) {
              let firstField = this.$refs[invalidFields[0]];
              firstField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
            if (this.mobileNo && this.mobileNoCountryCode) {
              resolve(validationResponse);
            }
          });
      });
    },
    onChangePermanentAddress() {
      this.onChangeAddressFlag();
    },
  },
};
</script>
