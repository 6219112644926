<template>
  <div>
    <v-form v-if="!formListLoader">
      <ValidationObserver ref="basicInfoValidationObserver">
        <v-row class="ma-4">
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="salutationProvider"
              v-slot="{ errors }"
              name="Salutation"
              rules="required"
            >
              <v-autocomplete
                :id="'Salutation'"
                v-model="salutation"
                outlined
                :items="salutationList"
                required
                @change="salutationListChange"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Salutation<span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="firstNameProvider"
              v-slot="{ errors }"
              name="First Name"
              rules="required|multilingualNameValidation"
            >
              <v-text-field
                :id="'First_Name'"
                v-model="firstName"
                outlined
                :counter="50"
                :maxlength="50"
                :error-messages="errors"
              >
                <template v-slot:label>
                  First Name<span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="middleNameProvider"
              v-slot="{ errors }"
              name="Middle Name"
              rules="multilingualNameValidation"
            >
              <v-text-field
                :id="'Middle_Name'"
                v-model="middleName"
                outlined
                :counter="50"
                :maxlength="50"
                label="Middle Name"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="lastNameProvider"
              v-slot="{ errors }"
              name="Last Name"
              rules="required|multilingualNameValidation"
            >
              <v-text-field
                :id="'Last_Name'"
                v-model="lastName"
                outlined
                :counter="50"
                :maxlength="50"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Last Name<span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="suffixProvider"
              v-slot="{ errors }"
              name="Suffix"
              rules="max:10"
            >
              <v-text-field
                :id="'Suffix'"
                v-model="suffix"
                outlined
                label="Suffix"
                :counter="10"
                :maxlength="10"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[232].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="knownAsProvider"
              v-slot="{ errors }"
              :name="formList[232].Field_Alias"
              :rules="
                formList[232].Mandatory_Field == 'Yes'
                  ? 'required|multilingualNameValidation'
                  : 'multilingualNameValidation'
              "
            >
              <v-text-field
                :id="'Nick_Name'"
                v-model="knownAs"
                outlined
                :counter="50"
                :maxlength="50"
                :label="formList[232].Field_Alias"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[232].Field_Alias }}
                  <span
                    v-if="formList[232].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[272].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="genderProvider"
              v-slot="{ errors }"
              name="Gender"
              :rules="formList[272].Mandatory_Field == 'Yes' ? 'required' : ''"
            >
              <v-autocomplete
                :id="'Gender'"
                v-model="gender"
                outlined
                :items="genderList"
                item-text="gender"
                item-value="genderId"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[272].Field_Alias }}
                  <span
                    v-if="formList[272].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[206].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="genderOrientationProvider"
              v-slot="{ errors }"
              :name="formList[206].Field_Alias"
              :rules="formList[206].Mandatory_Field == 'Yes' ? 'required' : ''"
            >
              <v-autocomplete
                :id="'GenderOrientation'"
                v-model="genderOrientation"
                outlined
                :items="genderOrientationList"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[206].Field_Alias }}
                  <span
                    v-if="formList[206].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[205].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="pronounProvider"
              v-slot="{ errors }"
              :name="formList[205].Field_Alias"
              :rules="formList[205].Mandatory_Field == 'Yes' ? 'required' : ''"
            >
              <v-autocomplete
                :id="'Pronoun'"
                v-model="pronoun"
                outlined
                :items="pronounList"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[205].Field_Alias }}
                  <span
                    v-if="formList[205].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider>
              <v-dialog v-model="dobModal" width="290">
                <template #activator="{ on }">
                  <ValidationProvider
                    ref="dobProvider"
                    v-slot="{ errors }"
                    name="Date of Birth"
                    rules="required"
                  >
                    <v-text-field
                      :id="'DateOfBirth'"
                      :value="dateFormattedDob"
                      outlined
                      readonly
                      v-on="on"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        Date of Birth<span style="color: red">*</span>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-if="dateOfBirth"
                  v-model="dateOfBirth"
                  :max="maxDob"
                  @change="saveDob(dateOfBirth)"
                >
                </v-date-picker>
                <v-date-picker
                  v-else
                  v-model="dobPresentation"
                  :max="maxDob"
                  @change="saveDobFromPresentation(dobPresentation)"
                >
                </v-date-picker>
              </v-dialog>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="maritalStatusProvider"
              v-slot="{ errors }"
              name="Marital Status"
              rules="required"
            >
              <v-autocomplete
                :id="'MaritalStatus'"
                v-model="maritalStatus"
                outlined
                :items="maritalStatusList"
                item-text="Marital_Status"
                item-value="Marital_Status_Id"
                label="Marital Status*"
                @change="maritalStatusChange"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Marital Status<span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[301].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="birthPlaceProvider"
              v-slot="{ errors }"
              :name="formList[301].Field_Alias"
              :rules="
                formList[301].Mandatory_Field == 'Yes'
                  ? 'required|alphaSpace'
                  : 'alphaSpace'
              "
            >
              <v-text-field
                :id="'PlaceOfBirth'"
                v-model="placeOfBirth"
                outlined
                :counter="50"
                :maxlength="50"
                label="Birth Place"
                required
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[301].Field_Alias }}
                  <span
                    v-if="formList[301].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="bloodGroupsProvider"
              v-slot="{ errors }"
              name="Blood Group"
              rules="required"
            >
              <v-select
                :id="'BloodGroup'"
                v-model="bloodGroup"
                outlined
                :items="bloodGroupList"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Blood Group<span style="color: red">*</span>
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="languagesProvider"
              v-slot="{ errors }"
              name="Languages"
              rules=""
            >
              <v-autocomplete
                :id="'Languages'"
                v-model="languages"
                outlined
                :items="languagesList"
                :search-input.sync="languageSearch"
                item-text="Language_Name"
                item-value="Lang_Id"
                label="Languages Known"
                multiple
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="nationalityProvider"
              v-slot="{ errors }"
              name="Languages"
              rules="required"
            >
              <v-autocomplete
                :id="'Nationality'"
                v-model="nationalityId"
                :loading="nationalityLoading"
                outlined
                :items="nationalityList"
                :search-input.sync="nationalitySearch"
                item-text="nationality"
                item-value="nationalityId"
                label="Nationality"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Nationality<span style="color: red">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="nationalityId == 11"
          >
            <ValidationProvider
              ref="otherNationality"
              v-slot="{ errors }"
              name="Other Nationality"
              rules="required"
            >
              <v-text-field
                :id="'OtherNationality'"
                v-model="nationality"
                label="Other Nationality*"
                outlined
                :counter="100"
                :maxlength="100"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="personalEmailProvider"
              v-slot="{ errors }"
              name="Personal Email"
              rules="email|required"
            >
              <v-text-field
                :id="'PersonalEmail'"
                v-model="personalEmail"
                outlined
                :counter="100"
                :maxlength="100"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Personal Email<span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="isAustralian && formList[277].Field_Visiblity == 'Yes'"
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
          >
            <v-autocomplete
              :id="'EthnicRace'"
              v-model="aboriginal"
              label="Are you aboriginal or torres strait islander?"
              outlined
              :items="aboriginalList"
            ></v-autocomplete>
          </v-col>
          <v-col
            v-if="
              !isAustralian && formList[216].Field_Visiblity == 'Yes'
                ? true
                : false
            "
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
          >
            <ValidationProvider
              ref="panNumberProvider"
              v-slot="{ errors }"
              :name="formList[216].Field_Alias"
              :rules="
                formList[216].Mandatory_Field == 'Yes'
                  ? 'alphaNumeric|required'
                  : 'alphaNumeric'
              "
            >
              <v-text-field
                :id="'pan'"
                v-model="pan"
                outlined
                :counter="30"
                :maxlength="30"
                :label="formList[216].Field_Alias"
                @input="pan = pan.toUpperCase()"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[216].Field_Alias }}
                  <span
                    v-if="formList[216].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="
              !isAustralian && formList[215].Field_Visiblity == 'Yes'
                ? true
                : false
            "
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
          >
            <ValidationProvider
              ref="aadharNumberProvider"
              v-slot="{ errors }"
              :name="formList[215].Field_Alias"
              :rules="
                formList[215].Mandatory_Field == 'Yes'
                  ? 'required|aadhar'
                  : 'aadhar'
              "
            >
              <v-text-field
                :id="'aadhar'"
                v-model="aadhar"
                outlined
                :counter="12"
                :maxlength="12"
                :label="formList[215].Field_Alias"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[215].Field_Alias }}
                  <span
                    v-if="formList[215].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[217].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="insuranceNoProvider"
              v-slot="{ errors }"
              :name="formList[217].Field_Alias"
              :rules="
                formList[217].Mandatory_Field == 'Yes'
                  ? 'alphaNumSpaceWithFourSymbol|required'
                  : 'alphaNumSpaceWithFourSymbol'
              "
            >
              <v-text-field
                :id="'insuranceNo'"
                v-model="insuranceNo"
                outlined
                :counter="30"
                :maxlength="30"
                label="Insurance No / Philhealth"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[217].Field_Alias }}
                  <span
                    v-if="formList[217].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[218].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="npsProvider"
              v-slot="{ errors }"
              :name="formList[218].Field_Alias"
              :rules="
                formList[218].Mandatory_Field == 'Yes'
                  ? 'alphaNumSpaceWithFourSymbol|required'
                  : 'alphaNumSpaceWithFourSymbol'
              "
            >
              <v-text-field
                :id="'nps'"
                v-model="nps"
                outlined
                :counter="30"
                :maxlength="30"
                :label="formList[218].Field_Alias"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[218].Field_Alias }}
                  <span
                    v-if="formList[218].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[188].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="taxCodeProvider"
              v-slot="{ errors }"
              :name="formList[188].Field_Alias"
              :rules="
                formList[188].Mandatory_Field == 'Yes'
                  ? 'alphaNumSpaceWithFourSymbol|required'
                  : 'alphaNumSpaceWithFourSymbol'
              "
            >
              <v-autocomplete
                v-if="formList[188].Predefined == 'Yes'"
                :id="'TaxCode'"
                v-model="taxCode"
                :loading="taxCodeLoading"
                outlined
                :items="taxCodeList"
                :search-input.sync="taxCodeSearch"
                item-text="Tax_Description"
                item-value="Tax_Code"
                :label="formList[188].Field_Alias"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[188].Field_Alias }}
                  <span
                    v-if="formList[188].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-autocomplete>
              <v-text-field
                v-else
                :id="'taxCode'"
                v-model="taxCode"
                outlined
                :maxlength="30"
                :label="formList[188].Field_Alias"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[188].Field_Alias }}
                  <span
                    v-if="formList[188].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[219].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="uanProvider"
              v-slot="{ errors }"
              :name="formList[219].Field_Alias"
              :rules="
                formList[219].Mandatory_Field == 'Yes'
                  ? 'alphaNumSpaceWithFourSymbol|required'
                  : 'alphaNumSpaceWithFourSymbol'
              "
            >
              <v-text-field
                :id="'uan'"
                v-model="uan"
                outlined
                type="number"
                :counter="30"
                :maxlength="30"
                :label="formList[219].Field_Alias"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[219].Field_Alias }}
                  <span
                    v-if="formList[219].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row v-if="!isAustralian" class="ml-2">
          <v-col cols="12" class="text-h6 font-weight-medium">
            Other Information
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <ValidationProvider
              ref="hobbiesProvider"
              v-slot="{ errors }"
              name="Hobbies"
              rules="alphaNumSpaceWithFourSymbol"
            >
              <v-text-field
                :id="'hobbies'"
                v-model="hobbies"
                outlined
                :counter="100"
                :maxlength="100"
                label="Hobbies"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[277].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="ethnicRaceProvider"
              v-slot="{ errors }"
              name="Ethnic Race"
              :rules="
                formList[277].Mandatory_Field == 'Yes'
                  ? 'required|alphaNumSpaceWithFourSymbol'
                  : 'alphaNumSpaceWithFourSymbol'
              "
            >
              <v-text-field
                :id="'aadhar'"
                v-model="ethnicRace"
                outlined
                :counter="30"
                :maxlength="30"
                :label="formList[277].Field_Alias"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[277].Field_Alias }}
                  <span
                    v-if="formList[277].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[302].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="religionProvider"
              v-slot="{ errors }"
              :name="formList[302].Field_Alias"
              :rules="formList[302].Mandatory_Field == 'Yes' ? 'required' : ''"
            >
              <v-autocomplete
                :id="'Religion'"
                v-model="religionId"
                outlined
                :items="religionList"
                :search-input.sync="religionSearch"
                item-text="religion"
                item-value="religionId"
                :error-messages="errors"
              >
                <template v-slot:label>
                  {{ formList[302].Field_Alias }}
                  <span
                    v-if="formList[302].Mandatory_Field == 'Yes'"
                    style="color: red"
                    >*</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="religionId == 67"
          >
            <ValidationProvider
              ref="otherReligion"
              v-slot="{ errors }"
              name="Other Religion"
              rules="required"
            >
              <v-text-field
                :id="'OtherReligion'"
                v-model="religion"
                outlined
                :counter="100"
                :maxlength="100"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Other Religion
                  <span style="color: red">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="formList[241].Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="casteProvider"
              v-slot="{ errors }"
              name="Caste"
              rules="alphaNumSpaceWithFourSymbol"
            >
              <v-text-field
                :id="'caste'"
                v-model="caste"
                outlined
                :counter="30"
                :maxlength="30"
                label="Caste"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col>
            <VueTelInput
              v-show="false"
              :autoDefaultCountry="true"
              @country-changed="getCountryCode($event)"
            ></VueTelInput>
          </v-col>
        </v-row>
        <v-row v-if="!isAustralian" class="ml-2">
          <v-col
            cols="12"
            xs="12"
            sm="4"
            lg="4"
            md="4"
            xl="4"
            class="d-flex align-center"
          >
            <div class="mb-0 mr-3" style="color: #00000099; font-size: 0.9em">
              Do you identify as a person with a disability?
            </div>
            <v-switch
              :id="'disability'"
              v-model="disability"
              inset
              color="primary"
            >
            </v-switch>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="4"
            lg="4"
            md="4"
            xl="4"
            class="d-flex align-center"
          >
            <p class="mb-0 mr-3" style="color: #00000099; font-size: 0.9em">
              Have you ever served in the military?
            </p>
            <v-switch
              :id="'militaryService'"
              v-model="militaryService"
              inset
              color="primary"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="4"
            lg="4"
            md="4"
            xl="4"
            class="d-flex align-center"
          >
            <p class="mb-0 mr-3" style="color: #00000099; font-size: 0.9em">
              Are you a smoker?
            </p>
            <v-switch
              :id="'smokerProvider'"
              v-model="smoker"
              inset
              color="primary"
            ></v-switch>
          </v-col>
          <v-col v-if="smoker" cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
            <v-dialog v-model="smokerasofModel" width="290">
              <template #activator="{ on }">
                <ValidationProvider
                  ref="smokerasofprovider"
                  v-slot="{ errors }"
                  name="Smoker as of"
                >
                  <v-text-field
                    :id="'smokerasof'"
                    outlined
                    :value="formatDate(smokerasof)"
                    label="Smoker as of"
                    :disabled="!smoker"
                    v-on="on"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="smokerasof"
                :max="today"
                @change="smokerasofModel = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-form>
    <v-overlay absolute :value="formListLoader" color="#fff" z-index="1">
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  LIST_RELATIONSHIP,
  LIST_MARITAL_STATUS,
  LIST_LANGUAGE,
  LIST_NATIONALITY,
  LIST_RELIGION,
  LIST_GENDERS,
  GET_FORM_FIELDS_AND_TYPE,
  LIST_TAX_CODES,
} from "@/graphql/OnboardingQueries";
import moment from "moment";
import { VueTelInput } from "vue-tel-input";

export default {
  name: "BasicInformation",

  components: { VueTelInput },

  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // fields
    religionSearch: "",
    languageSearch: "",
    nationalitySearch: "",
    taxCodeSearch: "",
    salutation: "",
    firstName: "",
    lastName: "",
    middleName: "",
    knownAs: "",
    dateOfBirth: "",
    dobPresentation: "",
    placeOfBirth: "",
    gender: null,
    maritalStatus: null,
    bloodGroup: "",
    languages: [],
    nationality: "",
    nationalityId: "",
    personalEmail: "",
    aboriginal: "",
    pan: "",
    aadhar: "",
    hobbies: "",
    ethnicRace: "",
    caste: "",
    religion: "",
    religionId: "",
    insuranceNo: "",
    nps: "",
    pronoun: "",
    genderOrientation: "",
    disability: false,
    smoker: false,
    militaryService: false,
    smokerasof: "",
    smokerasofModel: false,
    suffix: "",
    isLoading: false,
    formListLoader: false,
    uan: null,
    taxCode: null,

    // list
    formList: {},
    maritalStatusList: [],
    genderList: [],
    taxCodeList: [],
    genderOrientationList: [
      "Ally",
      "Asexual",
      "Bisexual",
      "Female",
      "Gay",
      "Intersex",
      "Lesbian",
      "Male",
      "Pansexual",
      "Queer",
      "Questioning",
      "Transgender",
      "Transsexual",
    ],
    pronounList: ["He/Him", "She/Her", "They/Them"],
    salutationList: ["Mr", "Miss", "Mrs", "Dr", "Prof"],
    bloodGroupList: [
      "O+",
      "O-",
      "A+",
      "A-",
      "B+",
      "B-",
      "AB+",
      "AB-",
      "A1+",
      "A1B+",
      "Unknown",
    ],
    languagesList: [],
    nationalityList: [],
    religionList: [],
    aboriginalList: [
      "Aboriginal",
      "Aboriginal/Torres Strait Islander",
      "Neither Aboriginal nor Torres Strait Islander",
      "Torres Strait Islander",
    ],

    // others
    dobModal: false,
    countryCode: "",
  }),

  watch: {
    languages() {
      this.languageSearch = "";
    },
    nationalityId() {
      this.nationalitySearch = "";
    },
    religionId() {
      this.religionSearch = "";
    },
  },

  apollo: {
    listTimekeepingCareerDetail: {
      query: LIST_TAX_CODES,
      client: "apolloClientB",
      result({ data }) {
        if (
          data &&
          data.listTimekeepingCareerDetail &&
          data.listTimekeepingCareerDetail.taxDetail &&
          data.listTimekeepingCareerDetail.taxDetail.length > 0
        ) {
          this.taxCodeList = data.listTimekeepingCareerDetail.taxDetail;
        }
      },
    },
    retrieveGenderList: {
      query: LIST_GENDERS,
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
        };
      },
      result({ data }) {
        if (
          data &&
          data.retrieveGenderList &&
          data.retrieveGenderList.genderData
        ) {
          this.genderList = data.retrieveGenderList.genderData;
        }
      },
    },
    listMartialStatus: {
      query: LIST_MARITAL_STATUS,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listMartialStatus) {
          this.maritalStatusList = data.listMartialStatus.marital_status;
        }
      },
    },
    listLanguage: {
      query: LIST_LANGUAGE,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listLanguage) {
          this.languagesList = data.listLanguage.languages;
        }
      },
    },
    retrieveNationalityList: {
      query: LIST_NATIONALITY,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
        };
      },
      result({ data }) {
        if (data && data.retrieveNationalityList) {
          this.nationalityList = data.retrieveNationalityList.nationalityData;
          if (!this.nationality && !this.nationalityId) {
            this.assignNationalityBasedOnCode();
          }
        }
      },
    },
    retrieveReligionList: {
      query: LIST_RELIGION,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
        };
      },
      result({ data }) {
        if (data && data.retrieveReligionList) {
          this.religionList = data.retrieveReligionList.religionData;
        }
        // this.isLoading = false;
      },
    },
  },

  computed: {
    nationalityLoading() {
      return this.$apollo.queries.retrieveNationalityList.loading;
    },
    taxCodeLoading() {
      return this.$apollo.queries.listTimekeepingCareerDetail.loading;
    },
    today() {
      return moment().format("YYYY-MM-DD");
    },
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
    isAustralian() {
      let country = localStorage.getItem("country");
      if (country && country.toLowerCase() === "au") {
        return true;
      }
      return false;
    },
    dateFormattedDob() {
      return this.dateOfBirth
        ? moment(this.dateOfBirth).format("DD/MM/YYYY")
        : "";
    },
    maxDob() {
      let currentDate = new Date();
      let currentMonth = (currentDate.getMonth() + 1).toString();
      let currentDay = (currentDate.getDate() - 1).toString();
      if (currentMonth.length == 1) {
        currentMonth = "0" + currentMonth;
      }
      if (currentDay.length == 1) {
        currentDay = "0" + currentDay;
      }
      return (
        currentDate.getFullYear() - 15 + "-" + currentMonth + "-" + currentDay
      );
    },
  },
  beforeMount() {
    this.getFieldProps();
  },
  mounted() {
    this.isLoading = true;
    this.dobPresentation = moment().subtract(15, "years").format("YYYY-MM-DD");
    if (this.personalInfo && Object.keys(this.personalInfo).length > 0) {
      this.salutation = this.personalInfo.Salutation;
      this.firstName = this.personalInfo.Emp_First_Name;
      this.lastName = this.personalInfo.Emp_Last_Name;
      this.middleName = this.personalInfo.Emp_Middle_Name;
      this.knownAs = this.personalInfo.Nick_Name;
      this.suffix = this.personalInfo.Appellation;
      this.dateOfBirth = this.personalInfo.DOB;
      this.placeOfBirth = this.personalInfo.Place_Of_Birth;
      this.gender = this.personalInfo.Gender_Id;
      this.maritalStatus = this.personalInfo.Marital_Status;
      this.bloodGroup = this.personalInfo.Blood_Group;
      this.languages = this.personalInfo.Lang_Known.languages.map((obj) => {
        return obj.Lang_Id;
      });
      this.nationalityId = this.personalInfo.Nationality_Id;
      this.nationality = this.personalInfo.Nationality;
      if (!this.nationalityId && !this.nationality) {
        this.assignNationalityBasedOnCode();
      }
      this.personalEmail = this.personalInfo.Personal_Email;
      this.aboriginal = this.personalInfo.Ethnic_Race;
      this.pan = this.personalInfo.PAN;
      this.aadhar = this.personalInfo.Aadhaar_Card_Number;
      this.hobbies = this.personalInfo.hobbies;
      this.ethnicRace = this.personalInfo.Ethnic_Race;
      this.caste = this.personalInfo.Caste;
      this.religionId = this.personalInfo.Religion_Id;
      this.religion = this.personalInfo.Religion;
      this.disability = this.personalInfo.Physically_Challenged;
      this.militaryService = this.personalInfo.Military_Service;
      this.smoker = this.personalInfo.Smoker;
      this.smokerasof = this.personalInfo.Smokerasof;
      this.genderOrientation = this.personalInfo.Gender_Orientations;
      this.pronoun = this.personalInfo.Pronoun;
      this.insuranceNo = this.personalInfo.Statutory_Insurance_Number;
      this.nps = this.personalInfo.PRAN_No;
      this.uan = this.personalInfo.UAN;
      this.taxCode = this.personalInfo.Tax_Code;
      localStorage.setItem("DOB", this.dateOfBirth);
    }
    this.isLoading = false;
    this.maritalStatusChange();
  },

  methods: {
    assignNationalityBasedOnCode() {
      let nationalityIdList = this.nationalityList.map((ele) => {
        return ele.nationalityId;
      });
      if (this.defaultCountryCode == "in") {
        if (nationalityIdList.includes(12)) {
          this.editedPersonalDetails.Nationality_Id = 12;
          this.editedPersonalDetails.Nationality = "Indian";
        }
      } else if (this.defaultCountryCode == "ph") {
        if (nationalityIdList.includes(3)) {
          this.editedPersonalDetails.Nationality_Id = 3;
          this.editedPersonalDetails.Nationality = "Filipino";
        }
      } else if (this.defaultCountryCode == "id") {
        if (nationalityIdList.includes(5)) {
          this.editedPersonalDetails.Nationality_Id = 5;
          this.editedPersonalDetails.Nationality = "Indonesian";
        }
      } else if (this.defaultCountryCode == "th") {
        if (nationalityIdList.includes(9)) {
          this.editedPersonalDetails.Nationality_Id = 9;
          this.editedPersonalDetails.Nationality = "Thai";
        }
      }
    },
    getCountryCode(code) {
      this.countryCode = code;
    },
    getFieldProps() {
      let vm = this;
      vm.formListLoader = true;
      this.$apollo
        .query({
          query: GET_FORM_FIELDS_AND_TYPE,
          variables: {
            form_Id: 178,
          },
          client: "apolloClientB",
        })
        .then((res) => {
          if (
            res.data.getFormFeildsByFormIdAndTab &&
            res.data.getFormFeildsByFormIdAndTab.formFields &&
            res.data.getFormFeildsByFormIdAndTab.formFields.length > 0
          ) {
            res.data.getFormFeildsByFormIdAndTab.formFields.forEach((list) => {
              this.formList[list.Field_Id] = list;
            });
          }
          vm.formListLoader = false;
        })
        .catch(() => {
          vm.formListLoader = false;
        });
    },
    basicInfoFields() {
      let religionValue = "",
        nationalityValue = "";
      let selectedReligion = this.religionList.filter(
        (el) => el.religionId == this.religionId
      );
      if (selectedReligion && selectedReligion.length > 0) {
        religionValue = selectedReligion[0].religion;
      }
      let selectedNationality = this.nationalityList.filter(
        (el) => el.nationalityId == this.nationalityId
      );
      if (selectedNationality && selectedNationality.length > 0) {
        nationalityValue = selectedNationality[0].nationality;
      }
      let genderTitle = this.genderList.filter((e) => {
        if (e.genderId == this.gender) {
          return e.gender;
        }
      });
      return {
        Salutation: this.salutation,
        Emp_First_Name: this.firstName,
        Emp_Middle_Name: this.middleName,
        Emp_Last_Name: this.lastName,
        Nick_Name: this.knownAs,
        Gender_Id: this.gender,
        DOB: this.dateOfBirth,
        Place_Of_Birth: this.placeOfBirth,
        Marital_Status: this.maritalStatus,
        Blood_Group: this.bloodGroup,
        Lang_Known: this.languages,
        hobbies: this.hobbies,
        Nationality:
          this.nationalityId == 11 ? this.nationality : nationalityValue,
        Nationality_Id: this.nationalityId,
        Religion: this.religionId == 67 ? this.religion : religionValue,
        Religion_Id: this.religionId,
        Caste: this.caste,
        Is_Manager: false,
        Personal_Email: this.personalEmail,
        Physically_Challenged: this.disability,
        Smoker: this.smoker,
        Smokerasof: this.smoker ? this.smokerasof : null,
        PAN: this.pan,
        Aadhaar_Card_Number: this.aadhar,
        Military_Service: this.militaryService,
        Ethnic_Race: this.ethnicRace ? this.ethnicRace : this.aboriginal,
        Appellation: this.suffix,
        Gender_Orientations: this.genderOrientation,
        Pronoun: this.pronoun,
        UAN: this.uan,
        Statutory_Insurance_Number: this.insuranceNo,
        PRAN_No: this.nps,
        Gender: genderTitle[0].gender,
        Tax_Code: this.taxCode,
      };
    },
    saveDob(value) {
      localStorage.setItem("DOB", value);
      this.dobModal = false;
    },
    saveDobFromPresentation(value) {
      this.dateOfBirth = value;
      localStorage.setItem("DOB", value);
      this.dobModal = false;
    },
    maritalStatusChange() {
      let vm = this;
      this.$apollo
        .query({
          query: LIST_RELATIONSHIP,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Marital_Status_Id: !this.maritalStatus
              ? 1
              : parseInt(this.maritalStatus, 10),
          },
          client: "apolloClientA",
        })
        .then((res) => {
          if (res.data) {
            const { marital_status_relation } =
              res.data.listMartialStatusRelation;
            vm.$emit("relationship-list", marital_status_relation);
          }
        });
    },
    salutationListChange() {
      this.gender =
        this.salutation === "Mr"
          ? 1
          : this.salutation === "Miss" || this.salutation === "Mrs"
          ? 0
          : null;
    },
    validateBasicInfo() {
      return new Promise((resolve) => {
        this.$refs.basicInfoValidationObserver
          .validate()
          .then((validationResponse) => {
            let invalidFields = [];
            Object.keys(this.$refs).forEach((refName) => {
              const field = this.$refs[refName];
              if (field && field.errors && field.errors.length > 0) {
                invalidFields.push(refName);
              }
            });
            if (invalidFields.length > 0) {
              let firstField = this.$refs[invalidFields[0]];
              firstField.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
            resolve(validationResponse);
          });
      });
    },
  },
};
</script>
